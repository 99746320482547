import { headerFlashBannerQuery, headerMenuQuery } from './queries'
import { fetchContentfulService } from '.'
import { MenuFlashBanner } from 'types/contentful/graphQlApi'
import { HeaderProps } from 'types/header'

type ContentfulHeaderVariables = { preview?: boolean }

export const fetchContentfulHeaderFlashBanner = (
  variables: ContentfulHeaderVariables
) =>
  fetchContentfulService<ContentfulHeaderVariables, MenuFlashBanner>({
    query: headerFlashBannerQuery,
    errorMessage: 'Error fetching header data',
    section: 'Header',
    variables
  })

export const fetchContentfulHeader = (variables: ContentfulHeaderVariables) =>
  fetchContentfulService<ContentfulHeaderVariables, HeaderProps>({
    query: headerMenuQuery,
    errorMessage: 'Error fetching header data',
    section: 'Header',
    variables
  })
