import HeaderLogo from '../../shared/HeaderLogo'
import SecondaryNavLinks from './SecondaryNavLinks'
import styles from './styles.module.scss'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { PrimaryNavLinks } from './PrimaryNavLinks'
import { HeaderLinks } from 'types/header'
import { getCookieByName } from '@helpers/session'
import { flyoutMenuTrackerAction } from 'store/redux/actions/popUpsTrackerActions'
import { CartEvent } from '@components/Header'
import useHeaderTheme from '@components/Header/useHeaderThemeHook'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

export default function DesktopHeader({
  data,
  onCartIsOpen,
  onCartOpen
}: {
  data: HeaderLinks
  onCartIsOpen: boolean
  onCartOpen: (e: CartEvent['event']) => void
}) {
  const { setHeaderTransparent } = useHeaderTheme()
  const dispatch = useDispatch()
  const [showFlyoutMenu, setShowFlyoutMenu] = useState(false)
  const [currentNav, setCurrentNav] = useState('')
  const links = data || []
  const linksWithSubmenu =
    links
      .filter(link => link?.grid && Object.keys(link?.grid).length > 0)
      .map(link => link?.title?.toLowerCase()) || []

  const resetMenu = () => {
    setShowFlyoutMenu(false)
    setCurrentNav('')
  }

  const handleHoverMenuItems = (title: string) => {
    setCurrentNav(title)
    setShowFlyoutMenu(linksWithSubmenu.includes(title?.toLowerCase()))
  }

  useEffect(() => {
    // Updates the Newsletter modal popup store status
    if (!getCookieByName('hideNewsletterPrompt')) {
      dispatch(flyoutMenuTrackerAction({ flyoutMenu: showFlyoutMenu }))
    }
  }, [dispatch, showFlyoutMenu])

  useEffect(() => {
    if (onCartIsOpen) {
      setShowFlyoutMenu(false)
    }
  }, [onCartIsOpen])

  useEffect(() => {
    dispatch(flyoutMenuTrackerAction({ flyoutMenu: showFlyoutMenu }))
  }, [dispatch, showFlyoutMenu])

  const handlEnterMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setHeaderTransparent(false)
  }

  return (
    <div
      onMouseLeave={() => {
        resetMenu()
        setHeaderTransparent(true)
      }}
      onMouseEnter={handlEnterMenu}
    >
      <div className={styles.DesktopHeader}>
        <HeaderLogo
          width="61"
          height="52"
          onMouseEnter={() => {
            resetMenu()
            setHeaderTransparent(false)
          }}
          classes={`${
            !!currentNav ? styles.DesktopHeader__headerLogoInactive : ''
          }`}
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'Logo'
            })
          }}
        />
        <PrimaryNavLinks
          links={links}
          currentNav={currentNav}
          handleShowMegamenu={handleHoverMenuItems}
          showFlyoutMenu={showFlyoutMenu}
        />
        <SecondaryNavLinks onCartOpen={onCartOpen} />
      </div>
      <div
        className={styles.DesktopHeader__fullBackground}
        data-state={showFlyoutMenu ? 'open' : 'closed'}
        onMouseEnter={resetMenu}
      />
    </div>
  )
}
