const gbCommon = {
  common: {
    'shop-now': 'Shop now',
    products: {
      trousers: 'Trousers',
      shorts: 'Shorts',
      tops: 'Tops',
      denim: 'Denim',
      belts: 'Belts',
      prices: {
        from: 'From'
      }
    },
    header: {
      'log-in': 'Log In',
      'my-account': 'My account'
    },
    form: {
      'email-placeholder': 'Email address',
      'enter-email-address': 'Enter email address',
      submit: 'Submit',
      preorder: 'Looking for your pre-order?'
    },
    sizes: {
      build: 'Build',
      'build-a-desc':
        'Our slimmest build, with a smaller seat and trimmer thigh.',
      'build-b-desc':
        'A more athletic build, sitting between a slim and a straight.',
      'build-c-desc':
        'The fit for anyone who struggles to find room for their thighs.',
      'taper-slim-desc':
        'A sharper taper below the knee, for a noticeably slim look - without being skinny.',
      'taper-standard-desc':
        'Our standard cut, with a gentle taper from knee to hem, for a sharp, contemporary look.',
      'taper-straight-desc':
        'Little to no taper from knee to hem, for a wider silhouette, and more space for meaty calves.',
      cut: 'Cut',
      inches: 'inches',
      pack: 'Pack',
      shortsleg: 'Shorts Leg',
      trunksleg: 'Trunks Leg',
      leg: 'Leg',
      'lower-leg': 'Lower Leg',
      length: 'Length',
      size: 'Size',
      waist: 'Waist',
      'chest-size': 'Chest Size'
    },
    sweeteners: {
      'more-sizes': '200+ sizes, finished to order',
      'free-returns': 'Free returns & delivery',
      'extended-returns': 'Extended 200 day returns'
    },
    sweetenersList: {
      'option-1': 'Free delivery on orders over',
      'option-2': 'Hassle-free returns',
      'option-3': '200 day free returns'
    },
    gdpr: {
      'terms-1':
        'Sign up to the (very good) SPOKE Newsletter today for 10% off your first order, and the scoop on new products and promotions.',
      'terms-2': 'Thrill-seeker? See the',
      'terms-3': 'T&Cs',
      'terms-4': 'and',
      'terms-5': 'privacy statement.'
    },
    'newsletter-modal': {
      heading: 'YOU KNOW THE DRILL',
      byline:
        "Sign up to be the first to know about new colours and styles. Land 10% off your first purchase whilst you're at it.",
      'first-purchase': '* First purchase only.',
      'cancel-link': 'Not right now',
      form: {
        'email-placeholder': 'Your email address',
        button: 'Get 10% off*'
      }
    },
    cart: {
      'no-items': "You haven't added to basket yet",
      basket: 'Basket',
      'free-delivery': 'Free delivery & returns',
      returns: '200 day returns - no questions asked',
      'check-out': 'Check out',
      'check-out-page': 'Check out',
      close: 'Close',
      'button-remove': 'Remove',
      total: 'Total',
      'invalid-items-removed':
        'Your cart was updated, some items were removed as they are no longer available',
      multibuy: {
        trunks: {
          label: '3 for £65',
          discount: 'Save £10',
          eligiblePrice: '25.00'
        },
        't-shirts': {
          label: '3 for £115',
          discount: 'Save £20',
          eligiblePrice: '45.00'
        },
        belts: {
          label: '£20 off',
          discount: '',
          eligiblePrice: '89.00'
        }
      },
      notifications: {
        'text-message':
          'Free shipping on orders under 150€. Orders over 150€ will be subject to a 20€ delivery fee to cover EU custom duties.',
        'text-link': 'Learn more.'
      }
    },
    footer: {
      about: 'About',
      careers: 'Careers',
      blog: 'Blog',
      help: 'Help',
      faq: 'FAQs',
      'return-policy': 'Return Policy',
      'size-chart': 'Size Chart',
      'contact-us': 'Contact Us',
      'arrange-return': 'Arrange a Return',
      shop: 'Shop',
      chinos: 'Chinos',
      'formal-trousers': 'Formal Trousers',
      'casual-trousers': 'Casual Trousers',
      denim: 'Jeans',
      shorts: 'Chino Shorts',
      swims: 'Swim Shorts',
      'fit-finder': 'Fit Finder',
      gifts: "Men's Gifts",
      'the-newsletter': 'Take 10% off your first order',
      privacy: 'Privacy',
      terms: 'Terms',
      'top-colours': 'Favourite Colours',
      'black-chinos': 'Black Chinos',
      'navy-chinos': 'Navy Chinos',
      'beige-chinos': 'Beige Chinos',
      'black-tops': 'Black Tops',
      'blue-tops': 'Blue Tops',
      'green-tops': 'Green Tops'
    },
    fit: {
      'takes-seconds': 'it only takes 50 seconds'
    },
    menu: {
      'my-account': 'My account',
      'log-in': 'Log In',
      'log-out': 'Log Out'
    },
    modelSizeImages: {
      button: {
        primaryText: 'See it on a model your size',
        secondaryText: 'Set in your size'
      },
      modal: {
        buttons: {
          default: 'Average Joe',
          plus: 'Hulky Harry'
        },
        title: 'Choose a model'
      }
    },
    seo: {
      'site-title': "SPOKE Men's Trousers - A flawless fit, delivered",
      'site-description':
        "Better fitting, better looking men's clothes, sold exclusively online. We finish to order in more sizes, for a flawless fit."
    },
    ctas: {
      'click-here': 'Click here'
    },
    'cookie-banner': {
      terms:
        'We use cookies to manage performance, personalised advertising and customer experience. By continuing, you agree to our use of cookies. To learn more about managing cookies please review our policy',
      link: 'here',
      'close-button': 'Accept'
    },
    layout: {
      error: {
        line1: 'We’re not quite sure what went wrong.',
        line2: 'You can go back, or try our',
        line3: 'if you need a hand.'
      }
    },
    reviews: {
      from: 'from'
    },
    email: {
      validation: 'Please enter an email',
      'incorrect-format': 'Email is incorrectly formatted'
    }
  }
}

module.exports = gbCommon
