import { HeaderGrid } from 'types/header'
import styles from './styles.module.scss'
import PrimaryLinksContainer from './shared/PrimaryLinksContainer'
import FeaturedImage from '@components/Header/shared/FeaturedImage'
import PromoImage from '@components/Header/shared/PromoImage'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

export default function PromoGrid({
  grid,
  currentNav
}: {
  grid: HeaderGrid
  currentNav: string
}) {
  const { gridLinks } = grid || []
  const promos = [
    {
      title: grid.promoImagePrimaryTitle,
      handle: grid.promoImagePrimaryHandle,
      image: grid.promoImagePrimary,
      visible: grid.promoImagePrimaryVisibility,
      badge: grid.promoImagePrimaryBadge
    },
    {
      title: grid.promoImageSecondaryTitle,
      handle: grid.promoImageSecondaryHandle,
      image: grid.promoImageSecondary,
      visible: grid.promoImageSecondaryVisibility,
      badge: grid.promoImageSecondaryBadge
    }
  ]

  const visiblePromos = promos.filter(promo => promo.visible)

  const handleClickFeaturedImage = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      columnType: 'Primary Image'
    })
  }

  const handleClickPromoImage = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      columnType: 'Secondary Image'
    })
  }

  const primaryLinks = gridLinks?.items.filter(
    item => item.variant === 'primary'
  )

  return (
    <div className={styles.Megamenu__Grid}>
      <div className={styles.Megamenu__Grid_links}>
        {primaryLinks?.map(gridLink => (
          <PrimaryLinksContainer
            key={`${gridLink.title}`}
            items={{ ...gridLink }}
            currentNav={currentNav}
          />
        ))}
      </div>
      {grid.featuredImageVisibility ? (
        <div
          className={`${styles.Megamenu__GridPromo_featuredImage} ${
            visiblePromos.length === 0 ? styles.promo_hidden : ''
          }`}
        >
          <FeaturedImage
            title={grid.featuredImageTitle}
            handle={grid.featuredImageHandle}
            buttonText={grid.featuredImageButtonText}
            image={grid.featuredImage}
            width={490}
            height={370}
            onClick={handleClickFeaturedImage}
          />
        </div>
      ) : null}
      {visiblePromos?.length ? (
        <div className={styles.Megamenu__Grid_promoImages}>
          {visiblePromos.map((promo, index) => (
            <PromoImage
              key={`${promo.title}-${index}`}
              {...promo}
              width={253}
              height={191}
              onClick={handleClickPromoImage}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}
