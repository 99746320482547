const euProduct = {
  product: {
    personalisation: {
      cost: '€10.00'
    },
    'returns-and-exchanges-instructions': {
      lines:
        'Standard delivery in 5-8 working days | Best-in-class 200-day returns policy | Free returns | Responsive and human customer care'
    }
  }
}

module.exports = euProduct
