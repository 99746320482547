import MenuButtonLink from '@components/Header/shared/MenuButtonLink'
import { HeaderLink, HeaderLinks } from 'types/header'
import styles from './styles.module.scss'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

export default function MobileMenuLinks({
  links,
  handlClickMenuItem,
  currentNav
}: {
  links: HeaderLinks
  handlClickMenuItem: (arg: HeaderLink) => void
  currentNav: string
}) {
  const handleMenuButtonClick = (link: HeaderLink) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: link.title,
      label: currentNav,
      contentType: 'Primary Links'
    })
    const linkWithSubmenu = link?.grid
    if (linkWithSubmenu) {
      handlClickMenuItem(link)
    } else {
      window.location.href = link.handle
    }
  }
  return (
    <ul className={styles.MobileMenuLinks}>
      {links.map(link => (
        <li>
          <MenuButtonLink
            size="large"
            title={link.title}
            link={link}
            handleOnClick={handleMenuButtonClick}
          />
        </li>
      ))}
    </ul>
  )
}
