import { getLocale } from '@helpers/env'
import { badgeFields, flashBannerNew, imageFields } from '../fragments/header'

export const headerFlashBannerQuery = `query($preview: Boolean) {
  menu: headerCollection(where: {title_contains: "Menu"}, limit: 1, locale: "${getLocale()}", preview: $preview) {
    items {
      ${flashBannerNew}
    }
  }
}`

export const headerMenuQuery = `query($preview: Boolean) {
  menu: headerCollection(where: {title_contains: "Main Menu"}, limit: 1, locale: "${getLocale()}", preview: $preview) {
    items {
      links: headerMainMenuLinksCollection(limit: 20, locale: "${getLocale()}") {
        items {
          title
          handle
          visibility
          highlightLink
          grid {
            variant
            featuredImageTitle
            featuredImageHandle
            featuredImageButtonText
            featuredImageVisibility
            featuredImage ${imageFields}
            featuredImageBadge ${badgeFields}
            promoImagePrimaryTitle
            promoImagePrimaryByline
            promoImagePrimaryHandle
            promoImagePrimaryVisibility
            promoImagePrimary ${imageFields}
            promoImagePrimaryBadge ${badgeFields}
            promoImageSecondaryTitle
            promoImageSecondaryByline
            promoImageSecondaryHandle
            promoImageSecondaryVisibility
            promoImageSecondary ${imageFields}
            promoImageSecondaryBadge ${badgeFields}
            gridLinks: linksCollection(limit: 10, locale: "${getLocale()}") {
              items {
                variant
                title
                links: linksCollection(limit: 10, locale: "${getLocale()}") {
                  items {
                    title
                    handle
                    visibility
                    badge {
                      title
                      badgeType
                      themeColour
                      small
                      iconName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}`
