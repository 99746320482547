import { ButtonLink } from '@components/ui/Button'
import styles from './styles.module.scss'
import { OPEN_FIT_FINDER } from '@consts/index'
import { getUserSavedFitData } from '@helpers/session/user'
import { getHelpdeskLink, getLink } from '@helpers/env'
import MenuLink from '@components/Header/shared/MenuLink'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

export default function SecondaryMenuLinks() {
  const userHasSavedFitCookies = getUserSavedFitData()

  return (
    <ul className={styles.SecondaryMenuLinks}>
      <li className={styles.SecondaryMenuLinks__button}>
        <ButtonLink
          href={`#${OPEN_FIT_FINDER}`}
          rawHref={true}
          data-testid="fitfinder-desktop-nav-button"
          variant="secondary"
          size="small"
          classes={styles.fitFinderMenuLink}
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'My Fit'
            })
          }}
        >
          {userHasSavedFitCookies ? 'My Fit' : 'Find your Fit'}
        </ButtonLink>
      </li>
      <li>
        <MenuLink
          link={getLink('/account')}
          title="My Account"
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'My Account'
            })
          }}
          variant="secondary"
        />
      </li>
      <li>
        <MenuLink
          link={getHelpdeskLink()}
          title="FAQs"
          target="_blank"
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'FAQs'
            })
          }}
          variant="secondary"
        />
      </li>
      <li>
        <MenuLink
          link={getLink('/pages/returns')}
          title="Returns"
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'Returns'
            })
          }}
          variant="secondary"
        />
      </li>
      <li>
        <MenuLink
          link={getLink('/pages/contact-us')}
          title="Contact Us"
          onClick={() => {
            analytics('Click', {
              category: HEADER_ANALYTICS_EVENT_NAMES.category.nav,
              label: 'Contact Us'
            })
          }}
          variant="secondary"
        />
      </li>
    </ul>
  )
}
