const usProduct = {
  product: {
    personalisation: {
      cost: '$10.00'
    },
    'returns-and-exchanges-instructions': {
      lines:
        'Standard delivery in 3-5 working days | Free Express Delivery on orders over $150 | Best-in-class 200-day returns policy | Free returns | Responsive and human customer care'
    }
  }
}

module.exports = usProduct
