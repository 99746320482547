import MenuLink from '@components/Header/shared/MenuLink'
import styles from './styles.module.scss'
import { HeaderGridLinks } from 'types/header'
import { analytics } from '@helpers/analytics'
import { HEADER_ANALYTICS_EVENT_NAMES } from '@components/Header/consts'

export default function PrimaryLinksContainer({
  items,
  currentNav
}: {
  items: HeaderGridLinks
  currentNav: string
}) {
  const { links } = items || []
  const visibleLinks = links?.items.filter(link => link.visibility)

  const handleClick = (title: string) => {
    analytics('Click', {
      category: HEADER_ANALYTICS_EVENT_NAMES.category.menu,
      title: title,
      label: currentNav,
      contentType: 'Primary Links'
    })
  }
  return (
    <ul className={styles.PrimaryLinksContainer}>
      {visibleLinks?.map(({ title, handle, badge }, index) => {
        const isLastItem =
          visibleLinks.length === index + 1 &&
          title?.toLowerCase().includes('view all')
        return (
          <li key={`primary-${title}-${index}`}>
            <MenuLink
              title={title}
              link={handle}
              size={`${isLastItem ? 'default' : 'large'}`}
              badge={badge}
              onClick={handleClick}
            />
          </li>
        )
      })}
    </ul>
  )
}
